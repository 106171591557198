<template>
  <div>
    <Hero />
    <InstallmentOptions />
    <RequiredDocuments />
    <ApplicationProcess />
    <FAQ />
    <CallToAction />
  </div>
</template>

<script>
export default {
  components: {
    Hero: () => import(/* webpackChunkName: "Hero" */ '@/views/Loan/Installment/sections/Hero'),
    InstallmentOptions: () => import(/* webpackChunkName: "InstallmentOptions" */ '@/views/Loan/Installment/sections/InstallmentOptions'),
    RequiredDocuments: () => import(/* webpackChunkName: "RequiredDocuments" */ '@/views/Loan/Installment/sections/RequiredDocuments'),
    ApplicationProcess: () => import(/* webpackChunkName: "ApplicationProcess" */ '@/views/Loan/Installment/sections/ApplicationProcess'),
    FAQ: () => import(/* webpackChunkName: "Hero" */ '@/views/Loan/Installment/sections/FAQ'),
    CallToAction: () => import(/* webpackChunkName: "CallToAction" */ '@/views/Loan/Installment/sections/CallToAction')
  },
  methods: {}
}
</script>

<style></style>
